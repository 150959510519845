/* button[type="submit"]:disabled {
  background: #dddddd;
  cursor: not-allowed;
} */

@media screen and (max-width: 767px) {
  .primary-button,
  .secondary-button {
    padding: 1rem 2rem;
  }
}

.primary-button {
  background-color: #333333;
  color: #ffffff;
  margin-left: 5px;

  &:hover {
    background: #757575;
  }
  &:focus {
    -webkit-box-shadow: 0px 0px 3px 4px #007eab;
    box-shadow: 0px 0px 3px 4px #007eab;
    outline: 0;
  }
}
.disabled {
  background: #d6d6d6;
  &:hover {
    background: #d6d6d6;
  }
  &:focus {
    -webkit-box-shadow: 0px 0px 3px 4px #007eab;
    box-shadow: 0px 0px 3px 4px #007eab;
    outline: 0;
  }
}
.loading {
  padding-right: 2.25em;
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
