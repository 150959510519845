.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -ms-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  z-index: 990;
}

.modal-popup {
  margin: 70px auto;
  background: #fff;
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  overflow-y: scroll;
  max-height: 95vh;

  &.close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
  }

  .content {
    max-height: 30%;
    overflow: auto;
  }
}

.modal-popup .close:hover {
  color: #06d85f;
}

.modal-header {
  border-bottom: 1px solid #f6f6f6;
}

.modal-body {
  margin-top: 3rem;
}