.anim_alert-y {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.anim_alert-y-enter {
  max-height: 0;
}
.anim_alert-y-enter-active {
  height: 100%;
  display: block;
  max-height: 100px;
  transition: max-height 0.5s ease-in;
  -webkit-transition: max-height 0.5s ease-in;
  -moz-transition: max-height 0.5s ease-in;
  -ms-transition: max-height 0.5s ease-in;
  -o-transition: max-height 0.5s ease-in;
}
.anim_alert-y-exit {
  height: 100%;
  max-height: 100px;
}
.anim_alert-y-exit-active {
  max-height: 0;
  transition: max-height 0.5s ease-in;
  -webkit-transition: max-height 0.5s ease-in;
  -moz-transition: max-height 0.5s ease-in;
  -ms-transition: max-height 0.5s ease-in;
  -o-transition: max-height 0.5s ease-in;
}

.anim_slide-y {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.anim_slide-y-entered {
  height: 100%;
  display: block;
  max-height: 700px;
  transition: max-height 0.25s ease-in;
  -webkit-transition: max-height 0.25s ease-in;
  -moz-transition: max-height 0.25s ease-in;
  -ms-transition: max-height 0.25s ease-in;
  -o-transition: max-height 0.25s ease-in;

  @media screen and (max-width: 767px) {
    max-height: inherit;
    overflow: inherit;
  }
}
.anim_slide-y-exiting {
  height: 100%;
  display: block;
  max-height: 700px;

  @media screen and (max-width: 767px) {
    max-height: auto;
  }
}
.anim_slide-y-exited {
  max-height: 0;
  transition: max-height 0.5s ease-in;
  -webkit-transition: max-height 0.5s ease-in;
  -moz-transition: max-height 0.5s ease-in;
  -ms-transition: max-height 0.5s ease-in;
  -o-transition: max-height 0.5s ease-in;
}
