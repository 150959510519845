#cookie-banner{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    background-color: #f9f9f9;
    box-shadow: 0px -5px 5.9084px 0px rgba(0, 0, 0, 0.05);
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    flex-shrink: 0;

    #cookie-banner-content-wrapper{
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        flex-shrink: 0;


        #cookie-disclaimer{
             // Typography
            color: var(--333333-brand-black, #333);
            font-feature-settings: 'clig' off, 'liga' off;

            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3125rem; /* 150% */

            // Layout
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 1rem;
            align-self: stretch;

            @media screen and (min-width: 769px) {
                font-size: 1rem;
                max-width: 75.375rem;
                align-self: center;
            }

            p{
                line-height: 1.5rem;
                flex: 1 0 0;
                line-height: 1.3125rem;

                @media screen and (min-width: 769px) {
                      line-height: 1.5rem;
                 }

                #cookie-banner-link{
                    font-weight: 700;
                    line-height: 1.3125rem;
                    text-decoration-line: underline;
                }
            }
        }

        .cookies-action-buttons{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
            flex-shrink: 0;

            @media screen and (min-width: 560px) {
                flex-direction: row-reverse;
                justify-content: center;
            }

            button{
                min-width: 12.5rem;
                max-width: 15.5rem;
                height: 2.5rem;
                padding: 0.75rem 2rem;
                justify-content: flex-end;
                align-items: center;
                gap: 0.625rem;
                flex-shrink: 0;
                margin: 0;
            }
        }
    }
}